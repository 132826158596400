// inspired by https://github.com/intlify/vue-i18n/tree/master/examples/lazy-loading/vite

import { createI18n } from "vue-i18n";
import { nextTick } from "vue";
//import en from "./locales/en.json";
//import { numberFormats } from "./numbers";
import datetimeFormats from "@/i18n/datetimes";

// Browser Language
import { useNavigatorLanguage } from '@vueuse/core'
const { language: browserLanguage } = useNavigatorLanguage()

// Expose supported locales (they may not be available on load, see loadLocaleMessages)
export const SUPPORTED_LOCALES = [
	{ label: "Français", val: "fr" },
	{ label: "English", val: "en" },
	{ label: "Deutsch", val: "de" },
	{ label: "Português", val: "pt" },
];

// Private instance of VueI18n object
let _i18n;

async function getDefaultLocale(authStore) {

	// Use the user's language is set
	if (authStore.language !== "") {
		return authStore.language;
	}

	// If the user is logged in, use the user's language
	await authStore.getLanguage();

	// If we cannot get the user's language, use the browser language
	if (authStore.language === "") {
		const browserLang = browserLanguage.value.slice(0, 2);
		if (!isSupportedLocale(browserLang)) {
			return SUPPORTED_LOCALES[0].val;
		}
		return browserLang;
	}

	// Else return the user's language if supported
	if (!isSupportedLocale(authStore.language)) {
		return SUPPORTED_LOCALES[0].val;
	}
	return authStore.language;
}

function isSupportedLocale(locale) {
	return SUPPORTED_LOCALES.map((l) => l.val).includes(locale);
}

// Initializer
async function setupI18n(options, authStore) {
	//console.log("setupI18n");
	const locale = await getDefaultLocale(authStore);
	_i18n = createI18n({ ...options, locale, datetimeFormats });
	setLocale(locale);
	return _i18n;
}

async function setDefaultLocale(authStore) {
	const locale = await getDefaultLocale(authStore);
	await setLocale(locale);
}

// Sets the active locale.
async function setLocale(newLocale) {
	await loadLocaleMessages(newLocale);
	//console.log(`i18n Set Locale: '${newLocale}'`);
	_i18n.global.locale.value = newLocale;
}

async function loadLocaleMessages(locale) {
	if (!_i18n.global.availableLocales.includes(locale)) {
		//console.log(`i18n Lazy Loading Locale: '${locale}'`);
		// load locale messages
		const messages = await import(`@/i18n/locales/${locale}.json`);

		// set locale and locale message
		_i18n.global.setLocaleMessage(locale, messages.default);
	}

	return nextTick();
}

// Public interface
export default {
	// Expose the VueI18n instance via a getter
	get vueI18n() {
		return _i18n;
	},
	setupI18n,
	setLocale,
	loadLocaleMessages,
	getDefaultLocale,
	setDefaultLocale
};
