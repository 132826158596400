import { computed } from "vue";
import { defineStore } from "pinia";

import { useAuthStore } from "./auth";
import { useCompanyStore } from "./company";

export const useUserContextStore = defineStore("userContext", () => {
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();

  const which = computed(() => {
    if (authStore.selectedEmployee && authStore.selectedEmployee.company) {
      return "employee";
    }
    if (companyStore.selectedCompanyId) {
      return "company";
    }
    return "unknown";
  });

  const company = computed(() => {
    return companyStore.selected;
  });

  return { which, company };
});
