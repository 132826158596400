import apolloClient from "@/graphql/client";

export async function handleGraphQLQuery(query, variables) {
  try {
    const result = await apolloClient.query({ query, variables, fetchPolicy: "no-cache" });
    if (result.data) {
      return { code: "SUCCESS", message: null, data: result.data };
    }
    return { code: "ERROR", message: "Error while loading data", data: null };
  } catch (e) {
    return { code: "ERROR", message: `${e.message} (${e.code})`, data: null };
  }
}
