import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

import { useSearchStore } from "./search";

// GraphQL
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "../graphql/client";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";

provideApolloClient(apolloClient);

// Upload a single document
const { mutate: uploadeAccountingFile } = useMutation(
  gql`
    mutation uploadAccountingFile($data: AccountingDocumentCreateInput!) {
      createAccountingDocument(data: $data) {
        id
      }
    }
  `
);

export const useFinanceStore = defineStore("finance", {
  state: () => ({
    documents: useStorage("financeDocuments", []),
    newDocuments: [],
    loading: false,
    typeFilters: [],
    yearFilters: [],
    monthFilters: [],
  }),
  getters: {
    allNewDocuments: (state) => state.newDocuments || [],
    checkIfNewDocumentsAreComplete: (state) => {
      let complete = true;
      state.newDocuments.forEach((doc) => {
        //console.log("doc", doc);
        Object.values(doc.data).some((value) => {
          if (!value) {
            complete = false;
          }
        });
      });
      //console.log("checkIfNewDocumentsAreComplete", complete);
      return complete;
    },
    filteredDocuments: (state) => {
      const byType = state.documents.filter((doc) => state.typeFilters.includes(doc.type));

      const byYear = byType.filter((doc) => state.yearFilters.includes(doc.date.substring(0, 4)));

      const byMonth = byYear.filter((doc) => state.monthFilters.includes(doc.date.substring(5, 7)));
      return byMonth;
    },
  },
  actions: {
    async getDocuments() {
      if (this.loading) return;

      this.documents = [];
      this.loading = true;
      /*
      const companyStore = useCompanyStore();
      if (!companyStore.selectedCompanyId) {
        this.documents = [];
        return { code: "ERROR", message: "noCompanySelected" };
      }
      */
      /*
      if (!useAuthStore().canAccessFinance) {
        this.documents = [];
        return { code: "ERROR", message: "Permission denied" };
      }
      */
      try {
        const AccountingDocumentWhereInput = useSearchStore().getAccountingFilter;

        //console.dir(AccountingDocumentWhereInput, { depth: null });
        // use apollo client directly
        const result = await apolloClient.query({
          query: gql`
            query AppFinanceDocuments($where: AccountingDocumentWhereInput!) {
              accountingDocuments(where: $where, orderBy: { createdAt: desc }) {
                id
                type
                date
                tag
                company {
                  id
                }
                updateAt
                createdAt
              }
            }
          `,
          variables: { ...AccountingDocumentWhereInput },
          fetchPolicy: "no-cache",
        });
        //console.dir(result);
        if (result.data && result.data.accountingDocuments) {
          if (result.data.accountingDocuments.length > 0) {
            this.documents = result.data.accountingDocuments;
            this.loading = false;
            return { code: "SUCCESS", message: "Load complete" };
          } else {
            this.documents = [];
            this.loading = false;
            return { code: "SUCCESS", message: "No documents found" };
          }
        } else {
          console.dir(result);
          this.loading = false;
          return { code: "ERROR", message: "Error while loading documents" };
        }
      } catch (e) {
        console.dir(e);
        this.loading = false;
        return { code: "ERROR", message: e.message + " (" + e.code + ")" };
      }
    },
    addNewDocuments(files) {
      let skippedFilesWithNames = [];
      for (let i = 0; i < files.length; i++) {
        const newDoc = {
          id: files[i].name + files[i].size + files[i].lastModified,
          data: {
            file: files[i],
            company: null,
            date: null,
            type: null,
            tag: null,
          },
        };
        // Only add if file is not already in the list (checking filename, size and lastModified)
        const alreadyAdded = this.newDocuments.filter((doc) => doc.id === newDoc.id);
        //console.log("alreadyAdded", alreadyAdded);
        //console.log(newDoc.id);
        if (!typeof alreadyAdded === "undefined" || alreadyAdded.length === 0) {
          this.newDocuments.push(newDoc);
        } else {
          skippedFilesWithNames.push(files[i].name);
        }
      }
      return skippedFilesWithNames;
    },
    updateDataOnKeyForNewDocumentUsingId(id, data, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        doc.data[key] = data;
      }
    },
    getValueForNewDocumentUsingId(id, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        return doc.data[key];
      } else {
        return null;
      }
    },
    removeNewFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const docIndex = this.newDocuments.findIndex((doc) => doc.id === files[i]);
        if (docIndex >= 0) {
          this.newDocuments.splice(docIndex, 1);
        }
      }
    },
    async uploadNewDocuments() {
      if (!this.checkIfNewDocumentsAreComplete) return;
      let uploadedIds = [];

      // Upload single file after another
      for (let i = 0; i < this.newDocuments.length; i++) {
        const element = this.newDocuments[i];
        const mutationData = {
          attachment: { upload: element.data.file },
          type: element.data.type.value,
          date: element.data.date.value,
          tag: element.data.tag.name,
          company: { connect: { id: element.data.company.id } },
        };
        try {
          const result = await uploadeAccountingFile({ data: mutationData });
          //console.log(result);
          if (result.data && result.data.createAccountingDocument && result.data.createAccountingDocument.id) {
            uploadedIds.push(element.id);
          }
        } catch (e) {
          console.dir(e);
        }
      }
      // Remove files that were successfully uploaded
      //console.log("uploadedIndexes (needed to remove)", uploadedIds);
      this.removeNewFiles(uploadedIds);

      // Update Store with new data
      await this.getDocuments();
    },
    resetData() {
      this.documents = [];
      this.newDocuments = [];
    },
  },
});
