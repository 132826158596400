import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth";
import { useCompanyStore } from "@/stores/company";

export function useIsHrEnabled() {
  const globalSetting = import.meta.env.VITE_FEATURE_HR_ENABLED;
  const authStore = useAuthStore();
  const companyStore = useCompanyStore();
  const { selectedEmployee } = storeToRefs(authStore);
  const { selected: selectedCompany } = storeToRefs(companyStore);

  const isHrEnabled = computed(() => {
    if (globalSetting && globalSetting === "yes") {
      // Check for account-level HR access
      if (selectedEmployee.value?.role?.canManageHumanResources === true) {
        return true;
      }

      // Check for company-level HR access
      if (selectedCompany.value?.hrEnabled === true) {
        return true;
      }
    }
    return false;
  });

  return isHrEnabled;
}
