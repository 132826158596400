import { onMounted, computed, ref } from "vue";
import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { Log, settings } from "@/oidc/settings";

const userManager = new UserManager({
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  ...settings,
});

export function useAuthManager() {
  const userInfo = ref({});
  const expiringDate = computed(() => {
    if (userInfo.value?.expires_at) {
      return new Date(userInfo.value?.expires_at * 1000);
    }
    return null;
  });
  const isSignedIn = computed(() => {
    return !!userInfo.value?.access_token && !!userInfo.value?.expires_at < new Date().getTime() / 1000;
  });

  /*
  onMounted(async () => {
    const user = await userManager.getUser();
    if (user) {
      console.log("Update User because of onMounted", user);
      userInfo.value = user;

      
      // If the Token expired, try to signin silently
      const now = Math.floor(Date.now() / 1000);
      console.log("now", now);
      console.log("expires_at", userInfo.value?.expires_at);
      if (now > !!userInfo.value?.expires_at) {
        console.log("Expired. Trying silent renew");
        userManager.startSilentRenew();
        //userManager.signinRedirect();
      }
      
    }
  });
  */
  /*
  userManager.events.addUserLoaded(async () => {
    console.log("userManager Internal: addUserLoaded");
    const usr = await userManager.getUser();
    console.dir(usr, { depth: null });
    if (usr) {
      //console.log("Update User because of addUserLoaded");
      userInfo.value = usr;
    }
  });
  */

  return { userInfo, expiringDate, userManager, isSignedIn };
}
