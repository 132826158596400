import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

//import { useCompanyStore } from "./company";
import { useEmployeeStore } from "../stores/employees";

import { makeDateFromFileNameDate, makeDateArray, isDateValid } from "../helpers/dateUtils";

// GraphQL
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "../graphql/client";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";

provideApolloClient(apolloClient);

// Upload a single document
const { mutate: uploadeSalaryFile } = useMutation(
  gql`
    mutation uploadSalaryFile($data: HrSalaryCreateInput!) {
      createHrSalary(data: $data) {
        id
      }
    }
  `
);

// Check for valid filenames (Example Sal_550_002-01_22023)
// https://regex101.com/r/BZ1F7y/2 (only remove the g at the end)
function isFilenameInAutoFormat(filename) {
  const regex = /Sal_(?<companycode>\d{1,6})_(?<usercode>\d{1,6}\-?\d*)_(?<date>\d{5,6})/i;
  const newRegex = /^(?<type>.{3,25})_(?<companycode>\d{1,6})_(?<usercode>\d{1,6}\-?\d*)_(?<date>\d{4,6})/i;

  //console.log("regex", regex.exec(filename));
  if (newRegex.test(filename)) {
    return newRegex.exec(filename).groups;
  }
  return false;
}

export const useHrSalaryStore = defineStore("salary", {
  state: () => ({
    documents: useStorage("salaries", []),
    newDocuments: [],
    loading: false,
    defaultDates: makeDateArray(),
    filenameTypes: new Map([
      ["Sal", "slip"],
      ["Certificat", "compensation"],
      ["Certificat-travail", "work"],
      ["Certificat-travail-U1", "worku1"],
    ]),
    salaryTypes: new Map([
      ["slip", "Salary Slip"],
      ["compensation", "Compensation certificate"],
      ["work", "Certificate of employment"],
      ["worku1", "Certificate of employment U1"],
    ]),
  }),
  getters: {
    allNewDocuments: (state) => state.newDocuments || [],
    checkIfNewDocumentsAreComplete: (state) => {
      let complete = true;
      state.newDocuments.forEach((doc) => {
        //console.log("doc", doc);
        Object.values(doc.data).some((value) => {
          if (!value) {
            complete = false;
          }
        });
      });
      //console.log("checkIfNewDocumentsAreComplete", complete);
      return complete;
    },
    fileTypeNameForKey: (state) => {
      return (fileTypeKey) => state.salaryTypes.get(fileTypeKey);
    },
  },
  actions: {
    async getDocuments() {
      if (this.loading) return;

      const employeeStore = useEmployeeStore();
      if (!employeeStore.selectedEmployee?.id) {
        this.documents = [];
        return { code: "ERROR", message: "No employee selected" };
      }

      this.documents = [];
      this.loading = true;
      try {
        // use apollo client directly
        const result = await apolloClient.query({
          query: gql`
            query AppSalaries($employeeId: ID) {
              hrSalaries(where: { employee: { id: { equals: $employeeId } } }, orderBy: { date: desc }) {
                id
                date
                type
                updateAt
                createdAt
              }
            }
          `,
          variables: { employeeId: employeeStore.selectedEmployee.id },
          fetchPolicy: "no-cache",
        });
        if (result.data && result.data.hrSalaries) {
          if (result.data.hrSalaries.length > 0) {
            this.documents = result.data.hrSalaries;
            this.loading = false;
            return { code: "SUCCESS", message: "Load complete" };
          }
          this.documents = [];
          this.loading = false;
          return { code: "SUCCESS", message: "No salaries found" };
        }
        //console.dir(result);
        this.loading = false;
        return { code: "ERROR", message: "Error while loading salaries" };
      } catch (e) {
        //console.dir(e);
        this.loading = false;
        return { code: "ERROR", message: `${e.message} (${e.code})` };
      }
    },
    /**
     * Method used from Dashboard or within Employee Context
     * If selectedUserId is null, the method will try to find the employee using the filename
     * If selectedUserId is set, the method will use the selectedUserId (if not already locked / auto-selected)
     *
     * @param {*} files
     * @param {*} selectedUserId
     * @returns list of skipped files with names
     */
    addNewDocuments(files, selectedUserId = null) {
      let skippedFilesWithNames = [];
      const employeeStore = useEmployeeStore();

      for (let i = 0; i < files.length; i++) {
        let userId = null;
        let employeeName = null;
        let employeeData = null;
        let lock = false;
        let defaultType = this.filenameTypes.get("Sal");
        let defaultDate = this.defaultDates[1].value;

        const groups = isFilenameInAutoFormat(files[i].name);
        // Set fields automatically if filename is in auto format
        if (groups) {
          // Filename is in auto format, extract data

          // Try to find employee using codes
          const userIdMatch = employeeStore.employeeUsingCodes(groups.usercode, groups.companycode);
          const extractedDate = makeDateFromFileNameDate(groups.date);
          const extractedType = this.filenameTypes.get(groups.type);

          // Lock employee data only if complete auto format is found
          if (extractedType && userIdMatch && isDateValid(extractedDate)) {
            userId = userIdMatch.id;
            employeeName = userIdMatch.name;
            employeeData = userIdMatch;
            defaultDate = extractedDate;
            defaultType = extractedType;
            lock = true;
          }
        }
        if (selectedUserId && lock === false) {
          userId = selectedUserId;
          employeeData = useEmployeeStore().allEmployeesReturnEmployeeUsingId(userId);
          employeeName = employeeData.name;
        }

        const newDoc = {
          id: files[i].name + files[i].size + files[i].lastModified,
          data: {
            file: files[i],
            employee: userId,
            date: defaultDate,
            type: defaultType,
          },
          meta: {
            employeeName: employeeName,
            employeeData: employeeData,
            duplicateFound: false,
            confirmed: false,
            locked: lock,
          },
        };
        // Only add if file is not already in the list (checking filename, size and lastModified)
        const alreadyAdded = this.newDocuments.filter((doc) => doc.id === newDoc.id);

        if (!typeof alreadyAdded === "undefined" || alreadyAdded.length === 0) {
          this.newDocuments.push(newDoc);
        } else {
          skippedFilesWithNames.push(files[i].name);
        }
      }
      return skippedFilesWithNames;
    },
    /**
     * Method used when we need to add files during blocked context (e.g. with company code and date)
     * Example when adding files from salary control. During this context, we must lock to company and date
     * Adding files works only with auto-format filenames
     * Invalid dates or company codes will be skipped
     * 
     * @param {*} files 
     * @param {*} companyCode 
     * @param {*} date / period
     * @returns list of skipped files with names
     */
    addNewDocumentsUsingCompanyCodeAndDate(files, companyCode, date) {
      let skippedFilesWithNames = [];
      const employeeStore = useEmployeeStore();

      for (let i = 0; i < files.length; i++) {
        let userId = null;
        let employeeName = null;
        let employeeData = null;
        let defaultType = this.filenameTypes.get("Sal");
        let defaultDate = this.defaultDates[1].value;

        const groups = isFilenameInAutoFormat(files[i].name);
        // Set fields automatically if filename is in auto format
        if (groups) {
          // Filename is in auto format, extract data

          // Try to find employee using codes
          const userIdMatch = employeeStore.employeeUsingCodes(groups.usercode, companyCode);
          const extractedDate = makeDateFromFileNameDate(groups.date);
          const extractedType = this.filenameTypes.get(groups.type);
          // console.dir(groups, { depth: null });
          // console.log("company code", companyCode);
          // console.log("date", date);
          // console.log("-----------------");
          // console.log("user id", groups.usercode);
          // console.log("extractedDate", extractedDate);
          // console.log("extractedType", extractedType);
          // console.log("userIdMatch", userIdMatch);


          // Lock employee data only if complete auto format is found
          if (extractedType && userIdMatch && groups.companycode === companyCode && isDateValid(extractedDate) && extractedDate === date) {
            userId = userIdMatch.id;
            employeeName = userIdMatch.name;
            employeeData = userIdMatch;
            defaultDate = extractedDate;
            defaultType = extractedType;
            

            const newDoc = {
              id: files[i].name + files[i].size + files[i].lastModified,
              data: {
                file: files[i],
                employee: userId,
                date: defaultDate,
                type: defaultType,
              },
              meta: {
                employeeName: employeeName,
                employeeData: employeeData,
                duplicateFound: false,
                confirmed: false,
                locked: true,
              },
            };
            // Only add if file is not already in the list (checking filename, size and lastModified)
            const alreadyAdded = this.newDocuments.filter((doc) => doc.id === newDoc.id);

            if (!typeof alreadyAdded === "undefined" || alreadyAdded.length === 0) {
              this.newDocuments.push(newDoc);
            } else {
              skippedFilesWithNames.push(files[i].name);
            }
          } else {
            skippedFilesWithNames.push(files[i].name);
          }
        } else {
          skippedFilesWithNames.push(files[i].name);
        }
      }
      return skippedFilesWithNames;
    },
    updateDataOnKeyForNewDocumentUsingId(id, data, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        doc.data[key] = data;
      }
    },
    getValueForNewDocumentUsingId(id, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        return doc.data[key];
      }
      return null;
    },
    getMetaForNewDocumentUsingId(id, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        return doc.meta[key];
      }
      return null;
    },
    updateMetaForNewDocumentUsingId(id, data, key) {
      const doc = this.newDocuments.find((doc) => doc.id === id);
      if (doc) {
        doc.meta[key] = data;
      }
    },
    removeNewFiles(files) {
      for (let i = 0; i < files.length; i++) {
        const docIndex = this.newDocuments.findIndex((doc) => doc.id === files[i]);
        if (docIndex >= 0) {
          this.newDocuments.splice(docIndex, 1);
        }
      }
    },
    /**
     * Check salaries for duplicates then Upload all new documents if documents are confirmed
     *
     * @returns {Promise<{uploadedIds: [], errors: []}>}
     */
    async uploadNewDocuments() {
      // Check for duplicates
      let unconfirmed = 0;
      const result = await this.loadPossibleDuplicateDocuments();
      if (result && result.data && result.data.hrSalaries) {
        //console.log("result.data.hrSalaries", result.data.hrSalaries);
        result.data.hrSalaries.forEach((doc) => {
          const docIndex = this.newDocuments.findIndex(
            (newDoc) => newDoc.data.employee === doc.employee.id && newDoc.data.date === doc.date
          );
          if (docIndex >= 0) {
            this.newDocuments[docIndex].meta.duplicateFound = true;
            unconfirmed++;
          }
        });
      }
      // Auto-confirm if no duplicates found
      this.newDocuments.forEach((doc) => {
        if (!doc.meta.duplicateFound) {
          doc.meta.confirmed = true;
        }
      });

      // Auto-confirm if no duplicates found
      for (const doc of this.newDocuments) {
        if (doc.meta.duplicateFound && doc.meta.confirmed === false) {
          unconfirmed++;
        }
      }

      if (unconfirmed > 0) {
        // Stop processing and return error
        return {
          uploadedIds: [],
          errors: ["Duplicates found. Please confirm or remove individual files to continue."],
        };
      }
      // Start real data upload
      return await this.startUploadingNewDocuments();
    },
    async startUploadingNewDocuments() {
      if (!this.checkIfNewDocumentsAreComplete) return;
      let uploadedIds = [];
      let errors = [];

      // Upload single file after another
      for (let i = 0; i < this.newDocuments.length; i++) {
        const element = this.newDocuments[i];
        const mutationData = {
          attachment: { upload: element.data.file },
          date: element.data.date,
          type: element.data.type,
          employee: { connect: { id: element.data.employee } },
        };
        try {
          const result = await uploadeSalaryFile({ data: mutationData });
          //console.log(result);
          if (result.data && result.data.createHrSalary && result.data.createHrSalary.id) {
            uploadedIds.push(element.id);
          } else if (result.errors) {
            errors.push(result.errors[0].message);
          }
        } catch (e) {
          console.log("Salaries Upload Exception");
          console.dir(e);
          errors.push(e.message);
        }
      }
      // Remove files that were successfully uploaded
      //console.log("uploadedIndexes (needed to remove)", uploadedIds);
      this.removeNewFiles(uploadedIds);

      // Update Store with new data
      //this.documents = [];
      await this.getDocuments();

      return { uploadedIds, errors };
    },
    queryFilterForDuplicateDocuments() {
      let query = [];
      this.newDocuments.forEach((doc) => {
        if ((!doc.meta.confirmed && !doc.meta.duplicateFound) || (doc.meta.confirmed && !doc.meta.duplicateFound)) {
          // Skip if check did alraedy happen
          query.push({
            employee: { id: { equals: doc.data.employee } },
            type: { equals: doc.data.type },
            date: { equals: doc.data.date },
          });
        }
      });
      return query;
    },
    loadPossibleDuplicateDocuments() {
      const query = this.queryFilterForDuplicateDocuments();
      if (query.length === 0) return;
      //console.log("query", query);
      //console.log("query", query);
      return apolloClient.query({
        query: gql`
          query AppSalaries($query: [HrSalaryWhereInput!]) {
            hrSalaries(where: { OR: $query }) {
              id
              employee {
                id
                name
              }
              type
              date
            }
          }
        `,
        variables: { query: query },
        fetchPolicy: "no-cache",
      });
    },
    resetData() {
      this.documents = [];
      this.newDocuments = [];
      this.loading = false;
    },
  },
});
